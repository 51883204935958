
/*globals angular, _, google */

(function() {
  var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  angular.module('uiGmapgoogle-maps.directives.api.models.parent').factory('uiGmapCircleParentModel', [
    'uiGmapLogger', '$timeout', 'uiGmapGmapUtil', 'uiGmapEventsHelper', 'uiGmapCircleOptionsBuilder', function($log, $timeout, GmapUtil, EventsHelper, Builder) {
      var CircleParentModel, _settingFromDirective;
      _settingFromDirective = function(scope, fn) {
        scope.settingFromDirective = true;
        fn();
        return $timeout(function() {
          return scope.settingFromDirective = false;
        });
      };
      return CircleParentModel = (function(superClass) {
        extend(CircleParentModel, superClass);

        CircleParentModel.include(GmapUtil);

        CircleParentModel.include(EventsHelper);

        function CircleParentModel(scope, element, attrs, gMap, DEFAULTS) {
          var clean, gObject, lastRadius;
          this.attrs = attrs;
          this.gMap = gMap;
          this.DEFAULTS = DEFAULTS;
          this.scope = scope;
          lastRadius = null;
          clean = (function(_this) {
            return function() {
              lastRadius = null;
              if (_this.listeners != null) {
                _this.removeEvents(_this.listeners);
                return _this.listeners = void 0;
              }
            };
          })(this);
          gObject = new google.maps.Circle(this.buildOpts(GmapUtil.getCoords(scope.center), scope.radius));
          this.setMyOptions = (function(_this) {
            return function(newVals, oldVals) {
              if (scope.settingFromDirective) {
                return;
              }
              if (!(_.isEqual(newVals, oldVals) && newVals === oldVals && ((newVals != null) && (oldVals != null) ? newVals.coordinates === oldVals.coordinates : true))) {
                return gObject.setOptions(_this.buildOpts(GmapUtil.getCoords(scope.center), scope.radius));
              }
            };
          })(this);
          this.props = this.props.concat([
            {
              prop: 'center',
              isColl: true
            }, {
              prop: 'fill',
              isColl: true
            }, 'radius', 'zIndex'
          ]);
          this.watchProps();
          if (this.scope.control != null) {
            this.scope.control.getCircle = function() {
              return gObject;
            };
          }
          clean();
          this.listeners = this.setEvents(gObject, scope, scope, ['radius_changed']) || [];
          this.listeners.push(google.maps.event.addListener(gObject, 'radius_changed', function() {

            /*
              possible google bug, and or because a circle has two radii
              radius_changed appears to fire twice (original and new) which is not too helpful
              therefore we will check for radius changes manually and bail out if nothing has changed
             */
            var newRadius, work;
            newRadius = gObject.getRadius();
            if (newRadius === lastRadius) {
              return;
            }
            lastRadius = newRadius;
            work = function() {
              return _settingFromDirective(scope, function() {
                var ref, ref1;
                if (newRadius !== scope.radius) {
                  scope.radius = newRadius;
                }
                if (((ref = scope.events) != null ? ref.radius_changed : void 0) && _.isFunction((ref1 = scope.events) != null ? ref1.radius_changed : void 0)) {
                  return scope.events.radius_changed(gObject, 'radius_changed', scope, arguments);
                }
              });
            };
            if (!angular.mock) {
              return scope.$evalAsync(function() {
                return work();
              });
            } else {
              return work();
            }
          }));
          this.listeners.push(google.maps.event.addListener(gObject, 'center_changed', function() {
            return scope.$evalAsync(function() {
              return _settingFromDirective(scope, function() {
                if (angular.isDefined(scope.center.type)) {
                  scope.center.coordinates[1] = gObject.getCenter().lat();
                  return scope.center.coordinates[0] = gObject.getCenter().lng();
                } else {
                  scope.center.latitude = gObject.getCenter().lat();
                  return scope.center.longitude = gObject.getCenter().lng();
                }
              });
            });
          }));
          scope.$on('$destroy', function() {
            clean();
            return gObject.setMap(null);
          });
          $log.info(this);
        }

        return CircleParentModel;

      })(Builder);
    }
  ]);

}).call(this);
