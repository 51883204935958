
/*global _,angular */

(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  angular.module('uiGmapgoogle-maps.directives.api').factory('uiGmapBasePolyChildModel', [
    'uiGmapLogger', '$timeout', 'uiGmaparray-sync', 'uiGmapGmapUtil', 'uiGmapEventsHelper', function($log, $timeout, arraySync, GmapUtil, EventsHelper) {
      return function(Builder, gFactory) {
        var BasePolyChildModel;
        return BasePolyChildModel = (function(superClass) {
          extend(BasePolyChildModel, superClass);

          BasePolyChildModel.include(GmapUtil);

          function BasePolyChildModel(arg) {
            var create, gObjectChangeCb, ref;
            this.scope = arg.scope, this.attrs = arg.attrs, this.gMap = arg.gMap, this.defaults = arg.defaults, this.model = arg.model, gObjectChangeCb = arg.gObjectChangeCb, this.isScopeModel = (ref = arg.isScopeModel) != null ? ref : false;
            this.clean = bind(this.clean, this);
            if (this.isScopeModel) {
              this.clonedModel = _.clone(this.model, true);
            }
            this.isDragging = false;
            this.internalEvents = {
              dragend: (function(_this) {
                return function() {
                  return _.defer(function() {
                    return _this.isDragging = false;
                  });
                };
              })(this),
              dragstart: (function(_this) {
                return function() {
                  return _this.isDragging = true;
                };
              })(this)
            };
            create = (function(_this) {
              return function() {
                var maybeCachedEval;
                if (_this.isDragging) {
                  return;
                }
                _this.pathPoints = _this.convertPathPoints(_this.scope.path);
                if (_this.gObject != null) {
                  _this.clean();
                }
                if (_this.scope.model != null) {
                  maybeCachedEval = _this.scope;
                }
                if (_this.pathPoints.length > 0) {
                  _this.gObject = gFactory(_this.buildOpts(_this.pathPoints, maybeCachedEval));
                }
                if (_this.gObject) {
                  arraySync(_this.gObject.getPath(), _this.scope, 'path', function(pathPoints) {
                    _this.pathPoints = pathPoints;
                    if (gObjectChangeCb != null) {
                      return gObjectChangeCb();
                    }
                  });
                  if (angular.isDefined(_this.scope.events) && angular.isObject(_this.scope.events)) {
                    _this.listeners = _this.model ? EventsHelper.setEvents(_this.gObject, _this.scope, _this.model) : EventsHelper.setEvents(_this.gObject, _this.scope, _this.scope);
                  }
                  return _this.internalListeners = _this.model ? EventsHelper.setEvents(_this.gObject, {
                    events: _this.internalEvents
                  }, _this.model) : EventsHelper.setEvents(_this.gObject, {
                    events: _this.internalEvents
                  }, _this.scope);
                }
              };
            })(this);
            create();
            this.scope.$watch('path', (function(_this) {
              return function(newValue, oldValue) {
                if (!_.isEqual(newValue, oldValue) || !_this.gObject) {
                  return create();
                }
              };
            })(this), true);
            if (!this.scope["static"] && angular.isDefined(this.scope.editable)) {
              this.scope.$watch('editable', (function(_this) {
                return function(newValue, oldValue) {
                  var ref1;
                  if (newValue !== oldValue) {
                    newValue = !_this.isFalse(newValue);
                    return (ref1 = _this.gObject) != null ? ref1.setEditable(newValue) : void 0;
                  }
                };
              })(this), true);
            }
            if (angular.isDefined(this.scope.draggable)) {
              this.scope.$watch('draggable', (function(_this) {
                return function(newValue, oldValue) {
                  var ref1;
                  if (newValue !== oldValue) {
                    newValue = !_this.isFalse(newValue);
                    return (ref1 = _this.gObject) != null ? ref1.setDraggable(newValue) : void 0;
                  }
                };
              })(this), true);
            }
            if (angular.isDefined(this.scope.visible)) {
              this.scope.$watch('visible', (function(_this) {
                return function(newValue, oldValue) {
                  var ref1;
                  if (newValue !== oldValue) {
                    newValue = !_this.isFalse(newValue);
                  }
                  return (ref1 = _this.gObject) != null ? ref1.setVisible(newValue) : void 0;
                };
              })(this), true);
            }
            if (angular.isDefined(this.scope.geodesic)) {
              this.scope.$watch('geodesic', (function(_this) {
                return function(newValue, oldValue) {
                  var ref1;
                  if (newValue !== oldValue) {
                    newValue = !_this.isFalse(newValue);
                    return (ref1 = _this.gObject) != null ? ref1.setOptions(_this.buildOpts(_this.gObject.getPath())) : void 0;
                  }
                };
              })(this), true);
            }
            if (angular.isDefined(this.scope.stroke) && angular.isDefined(this.scope.stroke.weight)) {
              this.scope.$watch('stroke.weight', (function(_this) {
                return function(newValue, oldValue) {
                  var ref1;
                  if (newValue !== oldValue) {
                    return (ref1 = _this.gObject) != null ? ref1.setOptions(_this.buildOpts(_this.gObject.getPath())) : void 0;
                  }
                };
              })(this), true);
            }
            if (angular.isDefined(this.scope.stroke) && angular.isDefined(this.scope.stroke.color)) {
              this.scope.$watch('stroke.color', (function(_this) {
                return function(newValue, oldValue) {
                  var ref1;
                  if (newValue !== oldValue) {
                    return (ref1 = _this.gObject) != null ? ref1.setOptions(_this.buildOpts(_this.gObject.getPath())) : void 0;
                  }
                };
              })(this), true);
            }
            if (angular.isDefined(this.scope.stroke) && angular.isDefined(this.scope.stroke.opacity)) {
              this.scope.$watch('stroke.opacity', (function(_this) {
                return function(newValue, oldValue) {
                  var ref1;
                  if (newValue !== oldValue) {
                    return (ref1 = _this.gObject) != null ? ref1.setOptions(_this.buildOpts(_this.gObject.getPath())) : void 0;
                  }
                };
              })(this), true);
            }
            if (angular.isDefined(this.scope.icons)) {
              this.scope.$watch('icons', (function(_this) {
                return function(newValue, oldValue) {
                  var ref1;
                  if (newValue !== oldValue) {
                    return (ref1 = _this.gObject) != null ? ref1.setOptions(_this.buildOpts(_this.gObject.getPath())) : void 0;
                  }
                };
              })(this), true);
            }
            this.scope.$on('$destroy', (function(_this) {
              return function() {
                _this.clean();
                return _this.scope = null;
              };
            })(this));
            if (angular.isDefined(this.scope.fill) && angular.isDefined(this.scope.fill.color)) {
              this.scope.$watch('fill.color', (function(_this) {
                return function(newValue, oldValue) {
                  if (newValue !== oldValue) {
                    return _this.gObject.setOptions(_this.buildOpts(_this.gObject.getPath()));
                  }
                };
              })(this));
            }
            if (angular.isDefined(this.scope.fill) && angular.isDefined(this.scope.fill.opacity)) {
              this.scope.$watch('fill.opacity', (function(_this) {
                return function(newValue, oldValue) {
                  if (newValue !== oldValue) {
                    return _this.gObject.setOptions(_this.buildOpts(_this.gObject.getPath()));
                  }
                };
              })(this));
            }
            if (angular.isDefined(this.scope.zIndex)) {
              this.scope.$watch('zIndex', (function(_this) {
                return function(newValue, oldValue) {
                  if (newValue !== oldValue) {
                    return _this.gObject.setOptions(_this.buildOpts(_this.gObject.getPath()));
                  }
                };
              })(this));
            }
          }

          BasePolyChildModel.prototype.clean = function() {
            var ref;
            EventsHelper.removeEvents(this.listeners);
            EventsHelper.removeEvents(this.internalListeners);
            if ((ref = this.gObject) != null) {
              ref.setMap(null);
            }
            return this.gObject = null;
          };

          return BasePolyChildModel;

        })(Builder);
      };
    }
  ]);

}).call(this);
