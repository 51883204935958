(function() {
  ['Polygon', 'Polyline'].forEach(function(name) {
    return angular.module('uiGmapgoogle-maps.directives.api.models.parent').factory("uiGmap" + name + "sParentModel", [
      'uiGmapBasePolysParentModel', "uiGmap" + name + "ChildModel", "uiGmapI" + name, function(BasePolysParentModel, ChildModel, IPoly) {
        return BasePolysParentModel(IPoly, ChildModel, name);
      }
    ]);
  });

}).call(this);
