
/*global _:true,angular:true */

(function() {
  var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  angular.module("uiGmapgoogle-maps.directives.api").factory("uiGmapMarker", [
    "uiGmapIMarker", "uiGmapMarkerChildModel", "uiGmapMarkerManager", "uiGmapLogger", function(IMarker, MarkerChildModel, MarkerManager, $log) {
      var Marker;
      return Marker = (function(superClass) {
        extend(Marker, superClass);

        function Marker() {
          Marker.__super__.constructor.call(this);
          this.template = '<span class="angular-google-map-marker" ng-transclude></span>';
          $log.info(this);
        }

        Marker.prototype.controller = [
          '$scope', '$element', function($scope, $element) {
            $scope.ctrlType = 'Marker';
            return _.extend(this, IMarker.handle($scope, $element));
          }
        ];

        Marker.prototype.link = function(scope, element, attrs, ctrl) {
          var mapPromise;
          mapPromise = IMarker.mapPromise(scope, ctrl);
          mapPromise.then(function(gMap) {
            var gManager, keys, m;
            gManager = new MarkerManager(gMap);
            keys = _.object(IMarker.keys, IMarker.keys);
            m = new MarkerChildModel({
              scope: scope,
              model: scope,
              keys: keys,
              gMap: gMap,
              doClick: true,
              gManager: gManager,
              doDrawSelf: false,
              trackModel: false
            });
            m.deferred.promise.then(function(gMarker) {
              return scope.deferred.resolve(gMarker);
            });
            if (scope.control != null) {
              return scope.control.getGMarkers = gManager.getGMarkers;
            }
          });
          return scope.$on('$destroy', function() {
            var gManager;
            if (typeof gManager !== "undefined" && gManager !== null) {
              gManager.clear();
            }
            return gManager = null;
          });
        };

        return Marker;

      })(IMarker);
    }
  ]);

}).call(this);
