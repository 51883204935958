
/*global _:true,angular:true, */

(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  angular.module("uiGmapgoogle-maps.directives.api.models.parent").factory("uiGmapMarkersParentModel", [
    "uiGmapIMarkerParentModel", "uiGmapModelsWatcher", "uiGmapPropMap", "uiGmapMarkerChildModel", "uiGmap_async", "uiGmapClustererMarkerManager", "uiGmapMarkerManager", "$timeout", "uiGmapIMarker", "uiGmapPromise", "uiGmapGmapUtil", "uiGmapLogger", "uiGmapSpiderfierMarkerManager", function(IMarkerParentModel, ModelsWatcher, PropMap, MarkerChildModel, _async, ClustererMarkerManager, MarkerManager, $timeout, IMarker, uiGmapPromise, GmapUtil, $log, SpiderfierMarkerManager) {
      var MarkersParentModel, _setPlurals;
      _setPlurals = function(val, objToSet) {
        objToSet.plurals = new PropMap();
        objToSet.scope.plurals = objToSet.plurals;
        return objToSet;
      };
      MarkersParentModel = (function(superClass) {
        extend(MarkersParentModel, superClass);

        MarkersParentModel.include(GmapUtil);

        MarkersParentModel.include(ModelsWatcher);

        function MarkersParentModel(scope, element, attrs, map) {
          this.maybeExecMappedEvent = bind(this.maybeExecMappedEvent, this);
          this.onDestroy = bind(this.onDestroy, this);
          this.newChildMarker = bind(this.newChildMarker, this);
          this.pieceMeal = bind(this.pieceMeal, this);
          this.rebuildAll = bind(this.rebuildAll, this);
          this.createAllNew = bind(this.createAllNew, this);
          this.bindToTypeEvents = bind(this.bindToTypeEvents, this);
          this.createChildScopes = bind(this.createChildScopes, this);
          this.validateScope = bind(this.validateScope, this);
          this.onWatch = bind(this.onWatch, this);
          MarkersParentModel.__super__.constructor.call(this, scope, element, attrs, map);
          this["interface"] = IMarker;
          _setPlurals(new PropMap(), this);
          this.scope.pluralsUpdate = {
            updateCtr: 0
          };
          this.$log.info(this);
          this.doRebuildAll = this.scope.doRebuildAll != null ? this.scope.doRebuildAll : false;
          this.setIdKey(this.scope);
          this.scope.$watch('doRebuildAll', (function(_this) {
            return function(newValue, oldValue) {
              if (newValue !== oldValue) {
                return _this.doRebuildAll = newValue;
              }
            };
          })(this));
          if (!this.modelsLength()) {
            this.modelsRendered = false;
          }
          this.scope.$watch('models', (function(_this) {
            return function(newValue, oldValue) {
              if (!_.isEqual(newValue, oldValue) || !_this.modelsRendered) {
                if (newValue.length === 0 && oldValue.length === 0) {
                  return;
                }
                _this.modelsRendered = true;
                return _this.onWatch('models', _this.scope, newValue, oldValue);
              }
            };
          })(this), !this.isTrue(attrs.modelsbyref));
          this.watch('doCluster', this.scope);
          this.watch('type', this.scope);
          this.watch('clusterOptions', this.scope);
          this.watch('clusterEvents', this.scope);
          this.watch('typeOptions', this.scope);
          this.watch('typeEvents', this.scope);
          this.watch('fit', this.scope);
          this.watch('idKey', this.scope);
          this.gManager = void 0;
          this.createAllNew(this.scope);
        }

        MarkersParentModel.prototype.onWatch = function(propNameToWatch, scope, newValue, oldValue) {
          if (propNameToWatch === "idKey" && newValue !== oldValue) {
            this.idKey = newValue;
          }
          if (this.doRebuildAll || (propNameToWatch === 'doCluster' || propNameToWatch === 'type')) {
            return this.rebuildAll(scope);
          } else {
            return this.pieceMeal(scope);
          }
        };

        MarkersParentModel.prototype.validateScope = function(scope) {
          var modelsNotDefined;
          modelsNotDefined = angular.isUndefined(scope.models) || scope.models === void 0;
          if (modelsNotDefined) {
            this.$log.error(this.constructor.name + ": no valid models attribute found");
          }
          return MarkersParentModel.__super__.validateScope.call(this, scope) || modelsNotDefined;
        };


        /*
        Not used internally by this parent
        created for consistency for external control in the API
         */

        MarkersParentModel.prototype.createChildScopes = function(isCreatingFromScratch) {
          if ((this.gMap == null) || (this.scope.models == null)) {
            return;
          }
          if (isCreatingFromScratch) {
            return this.createAllNew(this.scope, false);
          } else {
            return this.pieceMeal(this.scope, false);
          }
        };

        MarkersParentModel.prototype.bindToTypeEvents = function(typeEvents, events) {
          var internalHandles, self;
          if (events == null) {
            events = ['click', 'mouseout', 'mouseover'];
          }

          /*
            You should only be binding to events that produce groups/clusters of somthing.
            Otherwise use the orginal event handle.
            For Example: Click on a cluster pushes a cluster/group obj through which has getMarkers
            However Spiderfy's click is for a single marker so this is not ideal for that.
           */
          self = this;
          if (!this.origTypeEvents) {
            this.origTypeEvents = {};
            _.each(events, (function(_this) {
              return function(eventName) {
                return _this.origTypeEvents[eventName] = typeEvents != null ? typeEvents[eventName] : void 0;
              };
            })(this));
          } else {
            angular.extend(typeEvents, this.origTypeEvents);
          }
          internalHandles = {};
          _.each(events, function(eventName) {
            return internalHandles[eventName] = function(group) {
              return self.maybeExecMappedEvent(group, eventName);
            };
          });
          return angular.extend(typeEvents, internalHandles);
        };

        MarkersParentModel.prototype.createAllNew = function(scope) {
          var isSpiderfied, maybeCanceled, typeEvents, typeOptions;
          if (this.gManager != null) {
            if (this.gManager instanceof SpiderfierMarkerManager) {
              isSpiderfied = this.gManager.isSpiderfied();
            }
            this.gManager.clear();
            delete this.gManager;
          }
          typeEvents = scope.typeEvents || scope.clusterEvents;
          typeOptions = scope.typeOptions || scope.clusterOptions;
          if (scope.doCluster || scope.type === 'cluster') {
            if (typeEvents != null) {
              this.bindToTypeEvents(typeEvents);
            }
            this.gManager = new ClustererMarkerManager(this.map, void 0, typeOptions, typeEvents);
          } else if (scope.type === 'spider') {
            if (typeEvents != null) {
              this.bindToTypeEvents(typeEvents, ['spiderfy', 'unspiderfy']);
            }
            this.gManager = new SpiderfierMarkerManager(this.map, void 0, typeOptions, typeEvents, this.scope);
            if (isSpiderfied) {
              this.gManager.spiderfy();
            }
          } else {
            this.gManager = new MarkerManager(this.map);
          }
          if (this.didQueueInitPromise(this, scope)) {
            return;
          }
          maybeCanceled = null;
          return _async.promiseLock(this, uiGmapPromise.promiseTypes.create, 'createAllNew', (function(canceledMsg) {
            return maybeCanceled = canceledMsg;
          }), (function(_this) {
            return function() {
              return _async.each(scope.models, function(model) {
                _this.newChildMarker(model, scope);
                return maybeCanceled;
              }, _async.chunkSizeFrom(scope.chunk)).then(function() {
                _this.modelsRendered = true;
                if (scope.fit) {
                  _this.gManager.fit();
                }
                _this.gManager.draw();
                return _this.scope.pluralsUpdate.updateCtr += 1;
              }, _async.chunkSizeFrom(scope.chunk));
            };
          })(this));
        };

        MarkersParentModel.prototype.rebuildAll = function(scope) {
          var ref;
          if (!scope.doRebuild && scope.doRebuild !== void 0) {
            return;
          }
          if ((ref = this.scope.plurals) != null ? ref.length : void 0) {
            return this.onDestroy(scope).then((function(_this) {
              return function() {
                return _this.createAllNew(scope);
              };
            })(this));
          } else {
            return this.createAllNew(scope);
          }
        };

        MarkersParentModel.prototype.pieceMeal = function(scope) {
          var maybeCanceled, payload;
          if (scope.$$destroyed) {
            return;
          }
          maybeCanceled = null;
          payload = null;
          if (this.modelsLength() && this.scope.plurals.length) {
            return _async.promiseLock(this, uiGmapPromise.promiseTypes.update, 'pieceMeal', (function(canceledMsg) {
              return maybeCanceled = canceledMsg;
            }), (function(_this) {
              return function() {
                return uiGmapPromise.promise((function() {
                  return _this.figureOutState(_this.idKey, scope, _this.scope.plurals, _this.modelKeyComparison);
                })).then(function(state) {
                  payload = state;
                  return _async.each(payload.removals, function(child) {
                    if (child != null) {
                      if (child.destroy != null) {
                        child.destroy();
                      }
                      _this.scope.plurals.remove(child.id);
                      return maybeCanceled;
                    }
                  }, _async.chunkSizeFrom(scope.chunk));
                }).then(function() {
                  return _async.each(payload.adds, function(modelToAdd) {
                    _this.newChildMarker(modelToAdd, scope);
                    return maybeCanceled;
                  }, _async.chunkSizeFrom(scope.chunk));
                }).then(function() {
                  return _async.each(payload.updates, function(update) {
                    _this.updateChild(update.child, update.model);
                    return maybeCanceled;
                  }, _async.chunkSizeFrom(scope.chunk));
                }).then(function() {
                  if (payload.adds.length > 0 || payload.removals.length > 0 || payload.updates.length > 0) {
                    scope.plurals = _this.scope.plurals;
                    if (scope.fit) {
                      _this.gManager.fit();
                    }
                    _this.gManager.draw();
                  }
                  return _this.scope.pluralsUpdate.updateCtr += 1;
                });
              };
            })(this));
          } else {
            this.inProgress = false;
            return this.rebuildAll(scope);
          }
        };

        MarkersParentModel.prototype.newChildMarker = function(model, scope) {
          var child, childScope, keys;
          if (!model) {
            throw 'model undefined';
          }
          if (model[this.idKey] == null) {
            this.$log.error("Marker model has no id to assign a child to. This is required for performance. Please assign id, or redirect id to a different key.");
            return;
          }
          this.$log.info('child', child, 'markers', this.scope.markerModels);
          childScope = scope.$new(false);
          childScope.events = scope.events;
          keys = {};
          IMarker.scopeKeys.forEach(function(k) {
            return keys[k] = scope[k];
          });
          child = new MarkerChildModel({
            scope: childScope,
            model: model,
            keys: keys,
            gMap: this.map,
            defaults: this.DEFAULTS,
            doClick: this.doClick,
            gManager: this.gManager,
            doDrawSelf: false,
            isScopeModel: true
          });
          this.scope.plurals.put(model[this.idKey], child);
          return child;
        };

        MarkersParentModel.prototype.onDestroy = function(scope) {
          MarkersParentModel.__super__.onDestroy.call(this, scope);
          return _async.promiseLock(this, uiGmapPromise.promiseTypes["delete"], void 0, void 0, (function(_this) {
            return function() {
              return _async.each(_this.scope.plurals.values(), function(model) {
                if (model != null) {
                  return model.destroy(false);
                }
              }, _async.chunkSizeFrom(_this.scope.cleanchunk, false)).then(function() {
                if (_this.gManager != null) {
                  _this.gManager.destroy();
                }
                _this.plurals.removeAll();
                if (_this.plurals !== _this.scope.plurals) {
                  console.error('plurals out of sync for MarkersParentModel');
                }
                return _this.scope.pluralsUpdate.updateCtr += 1;
              });
            };
          })(this));
        };

        MarkersParentModel.prototype.maybeExecMappedEvent = function(group, fnName) {
          var pair, typeEvents;
          if (this.scope.$$destroyed) {
            return;
          }
          typeEvents = this.scope.typeEvents || this.scope.clusterEvents;
          if (_.isFunction(typeEvents != null ? typeEvents[fnName] : void 0)) {
            pair = this.mapTypeToPlurals(group);
            if (this.origTypeEvents[fnName]) {
              return this.origTypeEvents[fnName](pair.group, pair.mapped);
            }
          }
        };

        MarkersParentModel.prototype.mapTypeToPlurals = function(group) {
          var arrayToMap, mapped, ref;
          if (_.isArray(group)) {
            arrayToMap = group;
          } else if (_.isFunction(group.getMarkers)) {
            arrayToMap = group.getMarkers();
          }
          if (arrayToMap == null) {
            $log.error("Unable to map event as we cannot find the array group to map");
            return;
          }
          if ((ref = this.scope.plurals.values()) != null ? ref.length : void 0) {
            mapped = arrayToMap.map((function(_this) {
              return function(g) {
                return _this.scope.plurals.get(g.key).model;
              };
            })(this));
          } else {
            mapped = [];
          }
          return {
            cluster: group,
            mapped: mapped,
            group: group
          };
        };

        MarkersParentModel.prototype.getItem = function(scope, modelsPropToIterate, index) {
          if (modelsPropToIterate === 'models') {
            return scope[modelsPropToIterate][index];
          }
          return scope[modelsPropToIterate].get(index);
        };

        return MarkersParentModel;

      })(IMarkerParentModel);
      return MarkersParentModel;
    }
  ]);

}).call(this);
