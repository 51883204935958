(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  angular.module('uiGmapgoogle-maps.directives.api.managers').factory('uiGmapSpiderfierMarkerManager', [
    'uiGmapLogger', 'uiGmapFitHelper', 'uiGmapPropMap', 'uiGmapMarkerSpiderfier', function($log, FitHelper, PropMap, MarkerSpiderfier) {
      var SpiderfierMarkerManager;
      return SpiderfierMarkerManager = (function() {
        SpiderfierMarkerManager.type = 'SpiderfierMarkerManager';

        function SpiderfierMarkerManager(gMap, opt_markers, opt_options, opt_events, scope) {
          if (opt_markers == null) {
            opt_markers = {};
          }
          this.opt_options = opt_options != null ? opt_options : {};
          this.opt_events = opt_events;
          this.scope = scope;
          this.isSpiderfied = bind(this.isSpiderfied, this);
          this.getGMarkers = bind(this.getGMarkers, this);
          this.fit = bind(this.fit, this);
          this.destroy = bind(this.destroy, this);
          this.attachEvents = bind(this.attachEvents, this);
          this.clear = bind(this.clear, this);
          this.removeMany = bind(this.removeMany, this);
          this.remove = bind(this.remove, this);
          this.addMany = bind(this.addMany, this);
          this.update = bind(this.update, this);
          this.add = bind(this.add, this);
          this.type = SpiderfierMarkerManager.type;
          this.markerSpiderfier = new MarkerSpiderfier(gMap, this.opt_options);
          this.propMapGMarkers = new PropMap();
          this.attachEvents(this.opt_events, 'opt_events');
          this.noDrawOnSingleAddRemoves = true;
          $log.info(this);
        }

        SpiderfierMarkerManager.prototype.checkKey = function(gMarker) {
          var msg;
          if (gMarker.key == null) {
            msg = 'gMarker.key undefined and it is REQUIRED!!';
            return $log.error(msg);
          }
        };

        SpiderfierMarkerManager.prototype.add = function(gMarker) {
          gMarker.setMap(this.markerSpiderfier.map);
          this.checkKey(gMarker);
          this.markerSpiderfier.addMarker(gMarker, this.noDrawOnSingleAddRemoves);
          this.propMapGMarkers.put(gMarker.key, gMarker);
          return this.checkSync();
        };

        SpiderfierMarkerManager.prototype.update = function(gMarker) {
          this.remove(gMarker);
          return this.add(gMarker);
        };

        SpiderfierMarkerManager.prototype.addMany = function(gMarkers) {
          return gMarkers.forEach((function(_this) {
            return function(gMarker) {
              return _this.add(gMarker);
            };
          })(this));
        };

        SpiderfierMarkerManager.prototype.remove = function(gMarker) {
          var exists;
          this.checkKey(gMarker);
          exists = this.propMapGMarkers.get(gMarker.key);
          if (exists) {
            gMarker.setMap(null);
            this.markerSpiderfier.removeMarker(gMarker, this.noDrawOnSingleAddRemoves);
            this.propMapGMarkers.remove(gMarker.key);
          }
          return this.checkSync();
        };

        SpiderfierMarkerManager.prototype.removeMany = function(gMarkers) {
          return gMarkers.forEach((function(_this) {
            return function(gMarker) {
              return _this.remove(gMarker);
            };
          })(this));
        };

        SpiderfierMarkerManager.prototype.draw = function() {};

        SpiderfierMarkerManager.prototype.clear = function() {
          return this.removeMany(this.getGMarkers());
        };

        SpiderfierMarkerManager.prototype.attachEvents = function(options, optionsName) {
          if (angular.isDefined(options) && (options != null) && angular.isObject(options)) {
            return _.each(options, (function(_this) {
              return function(eventHandler, eventName) {
                if (options.hasOwnProperty(eventName) && angular.isFunction(options[eventName])) {
                  $log.info(optionsName + ": Attaching event: " + eventName + " to markerSpiderfier");
                  return _this.markerSpiderfier.addListener(eventName, function() {
                    if (eventName === 'spiderfy' || eventName === 'unspiderfy') {
                      return _this.scope.$evalAsync(options[eventName].apply(options, arguments));
                    } else {
                      return _this.scope.$evalAsync(options[eventName].apply(options, [arguments[0], eventName, arguments[0].model, arguments]));
                    }
                  });
                }
              };
            })(this));
          }
        };

        SpiderfierMarkerManager.prototype.clearEvents = function(options, optionsName) {
          var eventHandler, eventName;
          if (angular.isDefined(options) && (options != null) && angular.isObject(options)) {
            for (eventName in options) {
              eventHandler = options[eventName];
              if (options.hasOwnProperty(eventName) && angular.isFunction(options[eventName])) {
                $log.info(optionsName + ": Clearing event: " + eventName + " to markerSpiderfier");
                this.markerSpiderfier.clearListeners(eventName);
              }
            }
          }
        };

        SpiderfierMarkerManager.prototype.destroy = function() {
          this.clearEvents(this.opt_events, 'opt_events');
          return this.clear();
        };

        SpiderfierMarkerManager.prototype.fit = function() {
          return FitHelper.fit(this.getGMarkers(), this.markerSpiderfier.map);
        };

        SpiderfierMarkerManager.prototype.getGMarkers = function() {
          return this.markerSpiderfier.getMarkers();
        };

        SpiderfierMarkerManager.prototype.isSpiderfied = function() {
          return _.find(this.getGMarkers(), function(gMarker) {
            return (gMarker != null ? gMarker._omsData : void 0) != null;
          });
        };

        SpiderfierMarkerManager.prototype.checkSync = function() {};

        return SpiderfierMarkerManager;

      })();
    }
  ]);

}).call(this);
