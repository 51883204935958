(function() {
  angular.module('uiGmapgoogle-maps.directives.api.utils').service('uiGmapFitHelper', [
    'uiGmapLogger', '$timeout', function($log, $timeout) {
      return {
        fit: function(markersOrPoints, gMap) {
          var bounds, everSet, key, markerOrPoint, point;
          if (gMap && (markersOrPoints != null ? markersOrPoints.length : void 0)) {
            bounds = new google.maps.LatLngBounds();
            everSet = false;
            for (key in markersOrPoints) {
              markerOrPoint = markersOrPoints[key];
              if (markerOrPoint) {
                if (!everSet) {
                  everSet = true;
                }
                point = _.isFunction(markerOrPoint.getPosition) ? markerOrPoint.getPosition() : markerOrPoint;
              }
              bounds.extend(point);
            }
            if (everSet) {
              return $timeout(function() {
                return gMap.fitBounds(bounds);
              });
            }
          }
        }
      };
    }
  ]);

}).call(this);
