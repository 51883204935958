
/*global angular, _ */

(function() {
  angular.module("uiGmapgoogle-maps.directives.api").factory("uiGmapCircle", [
    "uiGmapICircle", "uiGmapCircleParentModel", function(ICircle, CircleParentModel) {
      return _.extend(ICircle, {
        link: function(scope, element, attrs, mapCtrl) {
          return mapCtrl.getScope().deferred.promise.then(function(gMap) {
            return new CircleParentModel(scope, element, attrs, gMap);
          });
        }
      });
    }
  ]);

}).call(this);
