
/*
@authors
Nicholas McCready - https://twitter.com/nmccready
Original idea from: http://stackoverflow.com/questions/22758950/google-map-drawing-freehand  , &
  http://jsfiddle.net/YsQdh/88/
 */

(function() {
  angular.module('uiGmapgoogle-maps.directives.api.models.child').factory('uiGmapDrawFreeHandChildModel', [
    'uiGmapLogger', '$q', function($log, $q) {
      var drawFreeHand, freeHandMgr;
      drawFreeHand = function(map, polys, done) {
        var move, poly;
        poly = new google.maps.Polyline({
          map: map,
          clickable: false
        });
        move = google.maps.event.addListener(map, 'mousemove', function(e) {
          return poly.getPath().push(e.latLng);
        });
        google.maps.event.addListenerOnce(map, 'mouseup', function(e) {
          var path;
          google.maps.event.removeListener(move);
          path = poly.getPath();
          poly.setMap(null);
          polys.push(new google.maps.Polygon({
            map: map,
            path: path
          }));
          poly = null;
          google.maps.event.clearListeners(map.getDiv(), 'mousedown');
          return done();
        });
        return void 0;
      };
      freeHandMgr = function(map1, scope) {
        var disableMap, enableMap;
        this.map = map1;
        disableMap = (function(_this) {
          return function() {
            var mapOptions;
            mapOptions = {
              draggable: false,
              disableDefaultUI: true,
              scrollwheel: false,
              disableDoubleClickZoom: false
            };
            $log.info('disabling map move');
            return _this.map.setOptions(mapOptions);
          };
        })(this);
        enableMap = (function(_this) {
          return function() {
            var mapOptions, ref;
            mapOptions = {
              draggable: true,
              disableDefaultUI: false,
              scrollwheel: true,
              disableDoubleClickZoom: true
            };
            if ((ref = _this.deferred) != null) {
              ref.resolve();
            }
            return _.defer(function() {
              return _this.map.setOptions(_.extend(mapOptions, scope.options));
            });
          };
        })(this);
        this.engage = (function(_this) {
          return function(polys1) {
            _this.polys = polys1;
            _this.deferred = $q.defer();
            disableMap();
            $log.info('DrawFreeHandChildModel is engaged (drawing).');
            google.maps.event.addDomListener(_this.map.getDiv(), 'mousedown', function(e) {
              return drawFreeHand(_this.map, _this.polys, enableMap);
            });
            return _this.deferred.promise;
          };
        })(this);
        return this;
      };
      return freeHandMgr;
    }
  ]);

}).call(this);
