
/*global _, angular */

(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  angular.module('uiGmapgoogle-maps.directives.api.models.parent').factory('uiGmapBasePolysParentModel', [
    '$timeout', 'uiGmapLogger', 'uiGmapModelKey', 'uiGmapModelsWatcher', 'uiGmapPropMap', 'uiGmap_async', 'uiGmapPromise', 'uiGmapFitHelper', function($timeout, $log, ModelKey, ModelsWatcher, PropMap, _async, uiGmapPromise, FitHelper) {
      return function(IPoly, PolyChildModel, gObjectName) {
        var BasePolysParentModel;
        return BasePolysParentModel = (function(superClass) {
          extend(BasePolysParentModel, superClass);

          BasePolysParentModel.include(ModelsWatcher);

          function BasePolysParentModel(scope, element, attrs, gMap1, defaults) {
            this.element = element;
            this.attrs = attrs;
            this.gMap = gMap1;
            this.defaults = defaults;
            this.maybeFit = bind(this.maybeFit, this);
            this.createChild = bind(this.createChild, this);
            this.pieceMeal = bind(this.pieceMeal, this);
            this.createAllNew = bind(this.createAllNew, this);
            this.watchIdKey = bind(this.watchIdKey, this);
            this.createChildScopes = bind(this.createChildScopes, this);
            this.watchDestroy = bind(this.watchDestroy, this);
            this.onDestroy = bind(this.onDestroy, this);
            this.rebuildAll = bind(this.rebuildAll, this);
            this.doINeedToWipe = bind(this.doINeedToWipe, this);
            this.watchModels = bind(this.watchModels, this);
            BasePolysParentModel.__super__.constructor.call(this, scope);
            this["interface"] = IPoly;
            this.$log = $log;
            this.plurals = new PropMap();
            _.each(IPoly.scopeKeys, (function(_this) {
              return function(name) {
                return _this[name + 'Key'] = void 0;
              };
            })(this));
            this.models = void 0;
            this.firstTime = true;
            this.$log.info(this);
            this.createChildScopes();
          }

          BasePolysParentModel.prototype.watchModels = function(scope) {

            /*
              This was watchCollection but not all model changes were being caught.
              TODO: Make the directive flexible in overriding whether we watch models (and depth) via watch or watchColleciton.
             */
            return scope.$watch('models', (function(_this) {
              return function(newValue, oldValue) {
                if (newValue !== oldValue) {
                  if (_this.doINeedToWipe(newValue) || scope.doRebuildAll) {
                    return _this.rebuildAll(scope, true, true);
                  } else {
                    return _this.createChildScopes(false);
                  }
                }
              };
            })(this), true);
          };

          BasePolysParentModel.prototype.doINeedToWipe = function(newValue) {
            var newValueIsEmpty;
            newValueIsEmpty = newValue != null ? newValue.length === 0 : true;
            return this.plurals.length > 0 && newValueIsEmpty;
          };

          BasePolysParentModel.prototype.rebuildAll = function(scope, doCreate, doDelete) {
            return this.onDestroy(doDelete).then((function(_this) {
              return function() {
                if (doCreate) {
                  return _this.createChildScopes();
                }
              };
            })(this));
          };

          BasePolysParentModel.prototype.onDestroy = function() {
            BasePolysParentModel.__super__.onDestroy.call(this, this.scope);
            return _async.promiseLock(this, uiGmapPromise.promiseTypes["delete"], void 0, void 0, (function(_this) {
              return function() {
                return _async.each(_this.plurals.values(), function(child) {
                  return child.destroy(true);
                }, _async.chunkSizeFrom(_this.scope.cleanchunk, false)).then(function() {
                  var ref;
                  return (ref = _this.plurals) != null ? ref.removeAll() : void 0;
                });
              };
            })(this));
          };

          BasePolysParentModel.prototype.watchDestroy = function(scope) {
            return scope.$on('$destroy', (function(_this) {
              return function() {
                return _this.rebuildAll(scope, false, true);
              };
            })(this));
          };

          BasePolysParentModel.prototype.createChildScopes = function(isCreatingFromScratch) {
            if (isCreatingFromScratch == null) {
              isCreatingFromScratch = true;
            }
            if (angular.isUndefined(this.scope.models)) {
              this.$log.error("No models to create " + gObjectName + "s from! I Need direct models!");
              return;
            }
            if ((this.gMap == null) || (this.scope.models == null)) {
              return;
            }
            this.watchIdKey(this.scope);
            if (isCreatingFromScratch) {
              return this.createAllNew(this.scope, false);
            } else {
              return this.pieceMeal(this.scope, false);
            }
          };

          BasePolysParentModel.prototype.watchIdKey = function(scope) {
            this.setIdKey(scope);
            return scope.$watch('idKey', (function(_this) {
              return function(newValue, oldValue) {
                if (newValue !== oldValue && (newValue == null)) {
                  _this.idKey = newValue;
                  return _this.rebuildAll(scope, true, true);
                }
              };
            })(this));
          };

          BasePolysParentModel.prototype.createAllNew = function(scope, isArray) {
            var maybeCanceled;
            if (isArray == null) {
              isArray = false;
            }
            this.models = scope.models;
            if (this.firstTime) {
              this.watchModels(scope);
              this.watchDestroy(scope);
            }
            if (this.didQueueInitPromise(this, scope)) {
              return;
            }
            maybeCanceled = null;
            return _async.promiseLock(this, uiGmapPromise.promiseTypes.create, 'createAllNew', (function(canceledMsg) {
              return maybeCanceled = canceledMsg;
            }), (function(_this) {
              return function() {
                return _async.map(scope.models, function(model) {
                  var child;
                  child = _this.createChild(model, _this.gMap);
                  if (maybeCanceled) {
                    $log.debug('createNew should fall through safely');
                    child.isEnabled = false;
                  }
                  maybeCanceled;
                  return child.pathPoints.getArray();
                }, _async.chunkSizeFrom(scope.chunk)).then(function(pathPoints) {
                  _this.maybeFit(pathPoints);
                  return _this.firstTime = false;
                });
              };
            })(this));
          };

          BasePolysParentModel.prototype.pieceMeal = function(scope, isArray) {
            var maybeCanceled, payload;
            if (isArray == null) {
              isArray = true;
            }
            if (scope.$$destroyed) {
              return;
            }
            maybeCanceled = null;
            payload = null;
            this.models = scope.models;
            if ((scope != null) && this.modelsLength() && this.plurals.length) {
              return _async.promiseLock(this, uiGmapPromise.promiseTypes.update, 'pieceMeal', (function(canceledMsg) {
                return maybeCanceled = canceledMsg;
              }), (function(_this) {
                return function() {
                  return uiGmapPromise.promise(function() {
                    return _this.figureOutState(_this.idKey, scope, _this.plurals, _this.modelKeyComparison);
                  }).then(function(state) {
                    payload = state;
                    if (payload.updates.length) {
                      _async.each(payload.updates, function(obj) {
                        _.extend(obj.child.scope, obj.model);
                        return obj.child.model = obj.model;
                      });
                    }
                    return _async.each(payload.removals, function(child) {
                      if (child != null) {
                        child.destroy();
                        _this.plurals.remove(child.model[_this.idKey]);
                        return maybeCanceled;
                      }
                    }, _async.chunkSizeFrom(scope.chunk));
                  }).then(function() {
                    return _async.each(payload.adds, function(modelToAdd) {
                      if (maybeCanceled) {
                        $log.debug('pieceMeal should fall through safely');
                      }
                      _this.createChild(modelToAdd, _this.gMap);
                      return maybeCanceled;
                    }, _async.chunkSizeFrom(scope.chunk)).then(function() {
                      return _this.maybeFit();
                    });
                  });
                };
              })(this));
            } else {
              this.inProgress = false;
              return this.rebuildAll(this.scope, true, true);
            }
          };

          BasePolysParentModel.prototype.createChild = function(model, gMap) {
            var child, childScope;
            childScope = this.scope.$new(false);
            this.setChildScope(IPoly.scopeKeys, childScope, model);
            childScope.$watch('model', (function(_this) {
              return function(newValue, oldValue) {
                if (newValue !== oldValue) {
                  return _this.setChildScope(IPoly.scopeKeys, childScope, newValue);
                }
              };
            })(this), true);
            childScope["static"] = this.scope["static"];
            child = new PolyChildModel({
              isScopeModel: true,
              scope: childScope,
              attrs: this.attrs,
              gMap: gMap,
              defaults: this.defaults,
              model: model,
              gObjectChangeCb: (function(_this) {
                return function() {
                  return _this.maybeFit();
                };
              })(this)
            });
            if (model[this.idKey] == null) {
              this.$log.error(gObjectName + " model has no id to assign a child to.\nThis is required for performance. Please assign id,\nor redirect id to a different key.");
              return;
            }
            this.plurals.put(model[this.idKey], child);
            return child;
          };

          BasePolysParentModel.prototype.maybeFit = function(pathPoints) {
            if (pathPoints == null) {
              pathPoints = this.plurals.map(function(p) {
                return p.pathPoints;
              });
            }
            if (this.scope.fit) {
              pathPoints = _.flatten(pathPoints);
              return FitHelper.fit(pathPoints, this.gMap);
            }
          };

          return BasePolysParentModel;

        })(ModelKey);
      };
    }
  ]);

}).call(this);
