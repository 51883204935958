
/*globals angular,_ */

(function() {
  angular.module("uiGmapgoogle-maps.directives.api.utils").factory("uiGmapPropertyAction", [
    "uiGmapLogger", function(Logger) {
      var PropertyAction;
      PropertyAction = function(setterFn) {
        this.setIfChange = function(callingKey) {
          return function(newVal, oldVal) {
            if (!_.isEqual(oldVal, newVal)) {
              return setterFn(callingKey, newVal);
            }
          };
        };
        this.sic = this.setIfChange;
        return this;
      };
      return PropertyAction;
    }
  ]);

}).call(this);
