
/*
Map Layers directive

This directive is used to create any type of Layer from the google maps sdk.
This directive creates a new scope.
 */

(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  angular.module('uiGmapgoogle-maps').directive("uiGmapMapTypes", [
    "$timeout", "uiGmapLogger", "uiGmapMapTypesParentModel", function($timeout, Logger, MapTypesParentModel) {
      var MapTypes;
      MapTypes = (function() {
        function MapTypes() {
          this.link = bind(this.link, this);
          this.$log = Logger;
          this.restrict = "EMA";
          this.require = '^' + 'uiGmapGoogleMap';
          this.priority = -1;
          this.transclude = true;
          this.template = '<span class=\"angular-google-map-layers\" ng-transclude></span>';
          this.scope = {
            mapTypes: "=mapTypes",
            show: "=show",
            options: "=options",
            refresh: "=refresh",
            id: "=idKey"
          };
        }

        MapTypes.prototype.link = function(scope, element, attrs, mapCtrl) {
          return mapCtrl.getScope().deferred.promise.then((function(_this) {
            return function(map) {
              return new MapTypesParentModel(scope, element, attrs, map);
            };
          })(this));
        };

        return MapTypes;

      })();
      return new MapTypes();
    }
  ]);

}).call(this);
