(function() {
  angular.module('uiGmapgoogle-maps.directives.api.utils').service('uiGmapIsReady', [
    '$q', '$timeout', function($q, $timeout) {
      var _checkIfReady, _ctr, _promises, _proms;
      _ctr = 0;
      _proms = [];
      _promises = function() {
        return $q.all(_proms);
      };
      _checkIfReady = function(deferred, expectedInstances, retriesLeft) {
        return $timeout(function() {
          if (retriesLeft <= 0) {
            deferred.reject('Your maps are not found we have checked the maximum amount of times. :)');
            return;
          }
          if (_ctr !== expectedInstances) {
            _checkIfReady(deferred, expectedInstances, retriesLeft - 1);
          } else {
            deferred.resolve(_promises());
          }
        }, 100);
      };
      return {
        spawn: function() {
          var d;
          d = $q.defer();
          _proms.push(d.promise);
          _ctr += 1;
          return {
            instance: _ctr,
            deferred: d
          };
        },
        promises: _promises,
        instances: function() {
          return _ctr;
        },
        promise: function(expectedInstances, numRetries) {
          var d;
          if (expectedInstances == null) {
            expectedInstances = 1;
          }
          if (numRetries == null) {
            numRetries = 50;
          }
          d = $q.defer();
          _checkIfReady(d, expectedInstances, numRetries);
          return d.promise;
        },
        reset: function() {
          _ctr = 0;
          _proms.length = 0;
        },
        decrement: function() {
          if (_ctr > 0) {
            _ctr -= 1;
          }
          if (_proms.length) {
            _proms.length -= 1;
          }
        }
      };
    }
  ]);

}).call(this);
