
/*global _:true, angular:true */

(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  angular.module('uiGmapgoogle-maps.directives.api.utils').factory('uiGmapModelKey', [
    'uiGmapBaseObject', 'uiGmapGmapUtil', function(BaseObject, GmapUtil) {
      return (function(superClass) {
        extend(_Class, superClass);

        function _Class(scope1, _interface) {
          this.scope = scope1;
          this["interface"] = _interface != null ? _interface : {
            scopeKeys: []
          };
          this.modelsLength = bind(this.modelsLength, this);
          this.updateChild = bind(this.updateChild, this);
          this.destroy = bind(this.destroy, this);
          this.setChildScope = bind(this.setChildScope, this);
          this.getChanges = bind(this.getChanges, this);
          this.getProp = bind(this.getProp, this);
          this.setIdKey = bind(this.setIdKey, this);
          this.modelKeyComparison = bind(this.modelKeyComparison, this);
          _Class.__super__.constructor.call(this);
          this.defaultIdKey = 'id';
          this.idKey = void 0;
        }

        _Class.prototype.evalModelHandle = function(model, modelKey) {
          if ((model == null) || (modelKey == null)) {
            return;
          }
          if (modelKey === 'self') {
            return model;
          } else {
            if (_.isFunction(modelKey)) {
              modelKey = modelKey();
            }
            return GmapUtil.getPath(model, modelKey);
          }
        };

        _Class.prototype.modelKeyComparison = function(model1, model2) {
          var coord1, coord2, hasCoords, isEqual, scope, without;
          hasCoords = this["interface"].scopeKeys.indexOf('coords') >= 0;
          if (hasCoords && (this.scope.coords != null) || !hasCoords) {
            scope = this.scope;
          }
          if (scope == null) {
            throw 'No scope set!';
          }
          if (hasCoords) {
            coord1 = this.scopeOrModelVal('coords', scope, model1);
            coord2 = this.scopeOrModelVal('coords', scope, model2);
            isEqual = GmapUtil.equalCoords(coord1, coord2);
            if (!isEqual) {
              return isEqual;
            }
          }
          without = _.without(this["interface"].scopeKeys, 'coords');
          isEqual = _.every(without, (function(_this) {
            return function(k) {
              var m1, m2;
              m1 = _this.scopeOrModelVal(scope[k], scope, model1);
              m2 = _this.scopeOrModelVal(scope[k], scope, model2);
              if (scope.deepComparison) {
                return _.isEqual(m1, m2);
              } else {
                return m1 === m2;
              }
            };
          })(this));
          return isEqual;
        };

        _Class.prototype.setIdKey = function(scope) {
          return this.idKey = scope.idKey != null ? scope.idKey : this.defaultIdKey;
        };

        _Class.prototype.setVal = function(model, key, newValue) {
          this.modelOrKey(model, key = newValue);
          return model;
        };

        _Class.prototype.modelOrKey = function(model, key) {
          if (key == null) {
            return;
          }
          if (key !== 'self') {
            return GmapUtil.getPath(model, key);
          }
          return model;
        };

        _Class.prototype.getProp = function(propName, scope, model) {
          return this.scopeOrModelVal(propName, scope, model);
        };


        /*
        For the cases were watching a large object we only want to know the list of props
        that actually changed.
        Also we want to limit the amount of props we analyze to whitelisted props that are
        actually tracked by scope. (should make things faster with whitelisted)
         */

        _Class.prototype.getChanges = function(now, prev, whitelistedProps) {
          var c, changes, prop;
          if (whitelistedProps) {
            prev = _.pick(prev, whitelistedProps);
            now = _.pick(now, whitelistedProps);
          }
          changes = {};
          prop = {};
          c = {};
          for (prop in now) {
            if (!prev || prev[prop] !== now[prop]) {
              if (_.isArray(now[prop])) {
                changes[prop] = now[prop];
              } else if (_.isObject(now[prop])) {
                c = this.getChanges(now[prop], (prev ? prev[prop] : null));
                if (!_.isEmpty(c)) {
                  changes[prop] = c;
                }
              } else {
                changes[prop] = now[prop];
              }
            }
          }
          return changes;
        };

        _Class.prototype.scopeOrModelVal = function(key, scope, model, doWrap) {
          var maybeWrap, modelKey, modelProp, scopeProp;
          if (doWrap == null) {
            doWrap = false;
          }
          maybeWrap = function(isScope, ret, doWrap) {
            if (doWrap == null) {
              doWrap = false;
            }
            if (doWrap) {
              return {
                isScope: isScope,
                value: ret
              };
            }
            return ret;
          };
          scopeProp = _.get(scope, key);
          if (_.isFunction(scopeProp)) {
            return maybeWrap(true, scopeProp(model), doWrap);
          }
          if (_.isObject(scopeProp)) {
            return maybeWrap(true, scopeProp, doWrap);
          }
          if (!_.isString(scopeProp)) {
            return maybeWrap(true, scopeProp, doWrap);
          }
          modelKey = scopeProp;
          if (!modelKey) {
            modelProp = _.get(model, key);
          } else {
            modelProp = modelKey === 'self' ? model : _.get(model, modelKey);
          }
          if (_.isFunction(modelProp)) {
            return maybeWrap(false, modelProp(), doWrap);
          }
          return maybeWrap(false, modelProp, doWrap);
        };

        _Class.prototype.setChildScope = function(keys, childScope, model) {
          var isScopeObj, key, name, newValue;
          for (key in keys) {
            name = keys[key];
            isScopeObj = this.scopeOrModelVal(name, childScope, model, true);
            if ((isScopeObj != null ? isScopeObj.value : void 0) != null) {
              newValue = isScopeObj.value;
              if (newValue !== childScope[name]) {
                childScope[name] = newValue;
              }
            }
          }
          return childScope.model = model;
        };

        _Class.prototype.onDestroy = function(scope) {};

        _Class.prototype.destroy = function(manualOverride) {
          var ref;
          if (manualOverride == null) {
            manualOverride = false;
          }
          if ((this.scope != null) && !((ref = this.scope) != null ? ref.$$destroyed : void 0) && (this.needToManualDestroy || manualOverride)) {
            return this.scope.$destroy();
          } else {
            return this.clean();
          }
        };

        _Class.prototype.updateChild = function(child, model) {
          if (model[this.idKey] == null) {
            this.$log.error("Model has no id to assign a child to. This is required for performance. Please assign id, or redirect id to a different key.");
            return;
          }
          return child.updateModel(model);
        };

        _Class.prototype.modelsLength = function(arrayOrObjModels) {
          var len, toCheck;
          if (arrayOrObjModels == null) {
            arrayOrObjModels = void 0;
          }
          len = 0;
          toCheck = arrayOrObjModels ? arrayOrObjModels : this.scope.models;
          if (toCheck == null) {
            return len;
          }
          if (angular.isArray(toCheck) || (toCheck.length != null)) {
            len = toCheck.length;
          } else {
            len = Object.keys(toCheck).length;
          }
          return len;
        };

        return _Class;

      })(BaseObject);
    }
  ]);

}).call(this);
