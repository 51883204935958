(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  angular.module('uiGmapgoogle-maps.directives.api.models.parent').factory('uiGmapMapTypeParentModel', [
    'uiGmapBaseObject', 'uiGmapLogger', function(BaseObject, Logger) {
      var MapTypeParentModel;
      MapTypeParentModel = (function(superClass) {
        extend(MapTypeParentModel, superClass);

        function MapTypeParentModel(scope, element, attrs, gMap, $log, childModel, propMap) {
          var watchChildModelOptions, watchChildModelShow, watchOptions, watchShow;
          this.scope = scope;
          this.element = element;
          this.attrs = attrs;
          this.gMap = gMap;
          this.$log = $log != null ? $log : Logger;
          this.childModel = childModel;
          this.propMap = propMap;
          this.refreshShown = bind(this.refreshShown, this);
          this.hideOverlay = bind(this.hideOverlay, this);
          this.showOverlay = bind(this.showOverlay, this);
          this.refreshMapType = bind(this.refreshMapType, this);
          this.createMapType = bind(this.createMapType, this);
          if (this.scope.options == null) {
            this.$log.info('options attribute for the map-type directive is mandatory. Map type creation aborted!!');
            return;
          }
          this.id = this.gMap.overlayMapTypesCount = this.gMap.overlayMapTypesCount + 1 || 0;
          this.doShow = true;
          this.createMapType();
          this.refreshShown();
          if (this.doShow && (this.gMap != null)) {
            this.showOverlay();
          }
          watchChildModelShow = (function(_this) {
            return function() {
              return _this.childModel[_this.attrs.show];
            };
          })(this);
          watchShow = this.childModel ? watchChildModelShow : 'show';
          this.scope.$watch(watchShow, (function(_this) {
            return function(newValue, oldValue) {
              if (newValue !== oldValue) {
                _this.doShow = newValue;
                if (newValue) {
                  return _this.showOverlay();
                } else {
                  return _this.hideOverlay();
                }
              }
            };
          })(this));
          watchChildModelOptions = (function(_this) {
            return function() {
              return _this.childModel[_this.attrs.options];
            };
          })(this);
          watchOptions = this.childModel ? watchChildModelOptions : 'options';
          this.scope.$watchCollection(watchOptions, (function(_this) {
            return function(newValue, oldValue) {
              var different, mapTypeProps;
              if (!_.isEqual(newValue, oldValue)) {
                mapTypeProps = ['tileSize', 'maxZoom', 'minZoom', 'name', 'alt'];
                different = _.some(mapTypeProps, function(prop) {
                  return !oldValue || !newValue || !_.isEqual(newValue[prop], oldValue[prop]);
                });
                if (different) {
                  return _this.refreshMapType();
                }
              }
            };
          })(this));
          if (angular.isDefined(this.attrs.refresh)) {
            this.scope.$watch('refresh', (function(_this) {
              return function(newValue, oldValue) {
                if (!_.isEqual(newValue, oldValue)) {
                  return _this.refreshMapType();
                }
              };
            })(this), true);
          }
          this.scope.$on('$destroy', (function(_this) {
            return function() {
              _this.hideOverlay();
              return _this.mapType = null;
            };
          })(this));
        }

        MapTypeParentModel.prototype.createMapType = function() {
          var id, idAttr, mapType;
          mapType = this.childModel ? (this.attrs.options ? this.childModel[this.attrs.options] : this.childModel) : this.scope.options;
          if (mapType.getTile != null) {
            this.mapType = mapType;
          } else if (mapType.getTileUrl != null) {
            this.mapType = new google.maps.ImageMapType(mapType);
          } else {
            this.$log.info('options should provide either getTile or getTileUrl methods. Map type creation aborted!!');
            return;
          }
          idAttr = this.attrs.id ? (this.childModel ? this.attrs.id : 'id') : void 0;
          id = idAttr ? (this.childModel ? this.childModel[idAttr] : this.scope[idAttr]) : void 0;
          if (id) {
            this.gMap.mapTypes.set(id, this.mapType);
            if (!angular.isDefined(this.attrs.show)) {
              this.doShow = false;
            }
          }
          this.mapType.layerId = this.id;
          if (this.childModel && angular.isDefined(this.scope.index)) {
            return this.propMap.put(this.mapType.layerId, this.scope.index);
          }
        };

        MapTypeParentModel.prototype.refreshMapType = function() {
          this.hideOverlay();
          this.mapType = null;
          this.createMapType();
          if (this.doShow && (this.gMap != null)) {
            return this.showOverlay();
          }
        };

        MapTypeParentModel.prototype.showOverlay = function() {
          var found;
          if (angular.isDefined(this.scope.index)) {
            found = false;
            if (this.gMap.overlayMapTypes.getLength()) {
              this.gMap.overlayMapTypes.forEach((function(_this) {
                return function(mapType, index) {
                  var layerIndex;
                  if (!found) {
                    layerIndex = _this.propMap.get(mapType.layerId.toString());
                    if (layerIndex > _this.scope.index || !angular.isDefined(layerIndex)) {
                      found = true;
                      _this.gMap.overlayMapTypes.insertAt(index, _this.mapType);
                    }
                  }
                };
              })(this));
              if (!found) {
                return this.gMap.overlayMapTypes.push(this.mapType);
              }
            } else {
              return this.gMap.overlayMapTypes.push(this.mapType);
            }
          } else {
            return this.gMap.overlayMapTypes.push(this.mapType);
          }
        };

        MapTypeParentModel.prototype.hideOverlay = function() {
          var found;
          found = false;
          return this.gMap.overlayMapTypes.forEach((function(_this) {
            return function(mapType, index) {
              if (!found && mapType.layerId === _this.id) {
                found = true;
                _this.gMap.overlayMapTypes.removeAt(index);
              }
            };
          })(this));
        };

        MapTypeParentModel.prototype.refreshShown = function() {
          return this.doShow = angular.isDefined(this.attrs.show) ? (this.childModel ? this.childModel[this.attrs.show] : this.scope.show) : true;
        };

        return MapTypeParentModel;

      })(BaseObject);
      return MapTypeParentModel;
    }
  ]);

}).call(this);
