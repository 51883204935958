
/*global _:true, angular:true */

(function() {
  angular.module('uiGmapgoogle-maps.extensions').service('uiGmapLodash', function() {
    var baseGet, baseToString, fixLodash, get, reEscapeChar, rePropName, toObject, toPath;
    rePropName = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\n\\]|\\.)*?)\2)\]/g;
    reEscapeChar = /\\(\\)?/g;

    /*
        For Lodash 4 compatibility (some aliases are removed)
     */
    fixLodash = function(arg) {
      var isProto, missingName, swapName;
      missingName = arg.missingName, swapName = arg.swapName, isProto = arg.isProto;
      if (_[missingName] == null) {
        _[missingName] = _[swapName];
        if (isProto) {
          return _.prototype[missingName] = _[swapName];
        }
      }
    };
    [
      {
        missingName: 'contains',
        swapName: 'includes',
        isProto: true
      }, {
        missingName: 'includes',
        swapName: 'contains',
        isProto: true
      }, {
        missingName: 'object',
        swapName: 'zipObject'
      }, {
        missingName: 'zipObject',
        swapName: 'object'
      }, {
        missingName: 'all',
        swapName: 'every'
      }, {
        missingName: 'every',
        swapName: 'all'
      }, {
        missingName: 'any',
        swapName: 'some'
      }, {
        missingName: 'some',
        swapName: 'any'
      }, {
        missingName: 'first',
        swapName: 'head'
      }, {
        missingName: 'head',
        swapName: 'first'
      }
    ].forEach(function(toMonkeyPatch) {
      return fixLodash(toMonkeyPatch);
    });
    if (_.get == null) {

      /**
       * Converts `value` to an object if it's not one.
       *
       * @private
       * @param {*} value The value to process.
       * @returns {Object} Returns the object.
       */
      toObject = function(value) {
        if (_.isObject(value)) {
          return value;
        } else {
          return Object(value);
        }
      };

      /**
       * Converts `value` to a string if it's not one. An empty string is returned
       * for `null` or `undefined` values.
       *
       * @private
       * @param {*} value The value to process.
       * @returns {string} Returns the string.
       */
      baseToString = function(value) {
        if (value === null) {
          return '';
        } else {
          return value + '';
        }
      };

      /**
       * Converts `value` to property path array if it's not one.
       *
       * @private
       * @param {*} value The value to process.
       * @returns {Array} Returns the property path array.
       */
      toPath = function(value) {
        var result;
        if (_.isArray(value)) {
          return value;
        }
        result = [];
        baseToString(value).replace(rePropName, function(match, number, quote, string) {
          result.push(quote ? string.replace(reEscapeChar, '$1') : number || match);
        });
        return result;
      };

      /**
       * The base implementation of `get` without support for string paths
       * and default values.
       *
       * @private
       * @param {Object} object The object to query.
       * @param {Array} path The path of the property to get.
       * @param {string} [pathKey] The key representation of path.
       * @returns {*} Returns the resolved value.
       */
      baseGet = function(object, path, pathKey) {
        var index, length;
        if (object === null) {
          return;
        }
        if (pathKey !== void 0 && pathKey in toObject(object)) {
          path = [pathKey];
        }
        index = 0;
        length = path.length;
        while (!_.isUndefined(object) && index < length) {
          object = object[path[index++]];
        }
        if (index && index === length) {
          return object;
        } else {
          return void 0;
        }
      };

      /**
       * Gets the property value at `path` of `object`. If the resolved value is
       * `undefined` the `defaultValue` is used in its place.
       *
       * @static
       * @memberOf _
       * @category Object
       * @param {Object} object The object to query.
       * @param {Array|string} path The path of the property to get.
       * @param {*} [defaultValue] The value returned if the resolved value is `undefined`.
       * @returns {*} Returns the resolved value.
       * @example
       *
       * var object = { 'a': [{ 'b': { 'c': 3 } }] };
       *
       * _.get(object, 'a[0].b.c');
       * // => 3
       *
       * _.get(object, ['a', '0', 'b', 'c']);
       * // => 3
       *
       * _.get(object, 'a.b.c', 'default');
       * // => 'default'
       */
      get = function(object, path, defaultValue) {
        var result;
        result = object === null ? void 0 : baseGet(object, toPath(path), path + '');
        if (result === void 0) {
          return defaultValue;
        } else {
          return result;
        }
      };
      _.get = get;
    }

    /*
        Author Nick McCready
        Intersection of Objects if the arrays have something in common each intersecting object will be returned
        in an new array.
     */
    this.intersectionObjects = function(array1, array2, comparison) {
      var res;
      if (comparison == null) {
        comparison = void 0;
      }
      res = _.map(array1, function(obj1) {
        return _.find(array2, function(obj2) {
          if (comparison != null) {
            return comparison(obj1, obj2);
          } else {
            return _.isEqual(obj1, obj2);
          }
        });
      });
      return _.filter(res, function(o) {
        return o != null;
      });
    };
    this.containsObject = _.includeObject = function(obj, target, comparison) {
      if (comparison == null) {
        comparison = void 0;
      }
      if (obj === null) {
        return false;
      }
      return _.some(obj, function(value) {
        if (comparison != null) {
          return comparison(value, target);
        } else {
          return _.isEqual(value, target);
        }
      });
    };
    this.differenceObjects = function(array1, array2, comparison) {
      if (comparison == null) {
        comparison = void 0;
      }
      return _.filter(array1, (function(_this) {
        return function(value) {
          return !_this.containsObject(array2, value, comparison);
        };
      })(this));
    };
    this.withoutObjects = this.differenceObjects;
    this.indexOfObject = function(array, item, comparison, isSorted) {
      var i, length;
      if (array == null) {
        return -1;
      }
      i = 0;
      length = array.length;
      if (isSorted) {
        if (typeof isSorted === "number") {
          i = (isSorted < 0 ? Math.max(0, length + isSorted) : isSorted);
        } else {
          i = _.sortedIndex(array, item);
          return (array[i] === item ? i : -1);
        }
      }
      while (i < length) {
        if (comparison != null) {
          if (comparison(array[i], item)) {
            return i;
          }
        } else {
          if (_.isEqual(array[i], item)) {
            return i;
          }
        }
        i++;
      }
      return -1;
    };
    this.isNullOrUndefined = function(thing) {
      return _.isNull(thing || _.isUndefined(thing));
    };
    return this;
  });

}).call(this);
