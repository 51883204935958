(function() {
  var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  angular.module('uiGmapgoogle-maps.directives.api.models.parent').factory('uiGmapMapTypesParentModel', [
    'uiGmapBaseObject', 'uiGmapLogger', 'uiGmapMapTypeParentModel', 'uiGmapPropMap', function(BaseObject, Logger, MapTypeParentModel, PropMap) {
      var MapTypesParentModel;
      MapTypesParentModel = (function(superClass) {
        extend(MapTypesParentModel, superClass);

        function MapTypesParentModel(scope, element, attrs, gMap, $log) {
          var pMap;
          this.scope = scope;
          this.element = element;
          this.attrs = attrs;
          this.gMap = gMap;
          this.$log = $log != null ? $log : Logger;
          if (this.attrs.mapTypes == null) {
            this.$log.info('layers attribute for the map-types directive is mandatory. Map types creation aborted!!');
            return;
          }
          pMap = new PropMap;
          this.scope.mapTypes.forEach((function(_this) {
            return function(l, i) {
              var childScope, mockAttr;
              mockAttr = {
                options: _this.scope.options,
                show: _this.scope.show,
                refresh: _this.scope.refresh
              };
              childScope = _this.scope.$new();
              childScope.index = i;
              new MapTypeParentModel(childScope, null, mockAttr, _this.gMap, _this.$log, l, pMap);
            };
          })(this));
        }

        return MapTypesParentModel;

      })(BaseObject);
      return MapTypesParentModel;
    }
  ]);

}).call(this);
