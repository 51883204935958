(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

  angular.module("uiGmapgoogle-maps.directives.api").factory("uiGmapControl", [
    "uiGmapIControl", "$http", "$templateCache", "$compile", "$controller", 'uiGmapGoogleMapApi', function(IControl, $http, $templateCache, $compile, $controller, GoogleMapApi) {
      var Control;
      return Control = (function(superClass) {
        extend(Control, superClass);

        function Control() {
          this.link = bind(this.link, this);
          Control.__super__.constructor.call(this);
        }

        Control.prototype.transclude = true;

        Control.prototype.link = function(scope, element, attrs, ctrl, transclude) {
          return GoogleMapApi.then((function(_this) {
            return function(maps) {
              var hasTranscludedContent, index, position;
              hasTranscludedContent = angular.isUndefined(scope.template);
              index = angular.isDefined(scope.index && !isNaN(parseInt(scope.index))) ? parseInt(scope.index) : void 0;
              position = angular.isDefined(scope.position) ? scope.position.toUpperCase().replace(/-/g, '_') : 'TOP_CENTER';
              if (!maps.ControlPosition[position]) {
                _this.$log.error('mapControl: invalid position property');
                return;
              }
              return IControl.mapPromise(scope, ctrl).then(function(map) {
                var control, controlDiv, pushControl;
                control = void 0;
                controlDiv = angular.element('<div></div>');
                pushControl = function(map, control, index) {
                  if (index) {
                    control[0].index = index;
                  }
                  return map.controls[google.maps.ControlPosition[position]].push(control[0]);
                };
                if (hasTranscludedContent) {
                  return transclude(function(transcludeEl) {
                    controlDiv.append(transcludeEl);
                    return pushControl(map, controlDiv.children(), index);
                  });
                } else {
                  return $http.get(scope.template, {
                    cache: $templateCache
                  }).then(function(arg) {
                    var data, templateCtrl, templateScope;
                    data = arg.data;
                    templateScope = scope.$new();
                    controlDiv.append(data);
                    if (angular.isDefined(scope.controller)) {
                      templateCtrl = $controller(scope.controller, {
                        $scope: templateScope
                      });
                      controlDiv.children().data('$ngControllerController', templateCtrl);
                    }
                    return control = $compile(controlDiv.children())(templateScope);
                  })["catch"](function(error) {
                    return _this.$log.error('mapControl: template could not be found');
                  }).then(function() {
                    return pushControl(map, control, index);
                  });
                }
              });
            };
          })(this));
        };

        return Control;

      })(IControl);
    }
  ]);

}).call(this);
